@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;300;400;500;600&family=Gayathri:wght@100;400;700&family=Poiret+One&family=Poppins:wght@100;200;400;700;800&family=Roboto+Mono:wght@100;200;300;500;700&family=Source+Sans+3:wght@300;400;600;700&family=Ubuntu:wght@300;500;700&display=swap");

body,
html {
  font-family: "Gayathri", "Poppins", sans-serif;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.App {
  width: 100dvw;
  max-width: 600px;
  display: grid;
  cursor: pointer;
  margin: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #474747;
}

::-webkit-scrollbar-thumb {
  background: #1f1f1f;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #393939;
}

.menu-link:hover {
  background-color: black;
}
